/* Contenedor principal */
.layout-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100dvw;
    height: 100dvh;
    margin: 0; /* Asegúrate de que no haya márgenes inesperados */
    padding: 0; /* Asegúrate de que no haya rellenos inesperados */
  }
  
  .content-container {
    flex-grow: 1;
    overflow-y: auto;
    max-width: 100vw;
    overflow-x: hidden;
    padding-bottom: 89.75px; /* Ajusta este padding para evitar que el contenido se oculte detrás de la navbar */
  }
  
  /* Navbar */
  .navbar {
    position: fixed;
    padding: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 10dvh;
    /*background-image: url('../img/nav_bg.png'); /* Usa la imagen de fondo */
    background-size:auto;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    z-index: 10; /* Asegúrate de que esté por encima del contenido */
  }
  
  /* Secciones de la navbar */
  .navbar-section {
    display: flex;
    gap: 32px;
    flex-grow: 1;
    justify-content: center; /* Centra las secciones */
  }
  
  .navbar-left {
    justify-content: end;
  }
  
  .navbar-right {
    justify-content: start;
  }
  
  /* Enlaces de la navbar */
  .navbar-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
  }
  
  /* Contenedor de iconos */
  .navbar-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Iconos de la navbar */
  .navbar-icon {
    width: 24px; /* Ajusta el tamaño del icono */
    transform: scale(1);
    transition: transform 0.2s;
  }
  
  .icon-selected {
    transform: scale(1.05);
  }
  
  /* Textos de la navbar */
  .navbar-text {
    font-size: 0.6rem;
    transition: color 0.2s;
  }
  
  .text-selected {
    color: #DB9F68;
  }
  
  /* Botón central (Review) */
  .navbar-center {
    display: flex;
    justify-content: center;
    flex-basis: 30%;
    flex-grow: 1;
  }

  .review-background {
    position: absolute;
    width: 70px; /* Un poco más grande que el botón de revisión */
    height: 70px; /* Un poco más grande que el botón de revisión */
    background-color: #92512E; /* Color de la navbar */
    border-radius: 50%;
    z-index: -1; /* Colocado detrás del botón de revisión */
  }
  
  .review-button {
    position: absolute;
    width: 55px;
    height: 55px;
    left: 50%;
    bottom: 7dvh;
    background-color: #630508;
    box-shadow: 0px 3px 10px #630508;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .review-icon {
    width: 32px;
    transform: scale(1);
    transition: transform 0.2s;
  }
  
  .review-selected .review-icon {
    transform: scale(1.10);
  }
  