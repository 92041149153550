
.fade-transition {
  transition: opacity 1.2s ease-out;
  opacity: 1;
}

.fade-transition.fade-out {
  opacity: 0;
}


::-webkit-scrollbar {
  width: 4px;
  height: 6px; /* Alto de la barra horizontal */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #630508;
}


/* media query para dispositivos celulares */

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #630508;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulseUpDown {
  0%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    /* transform: translateY(-10px); */
    opacity: 0.3;
  }
}

.animate-loading-logo {
  animation: pulseUpDown 1s infinite ease-in-out;
}
